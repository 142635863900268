.container {
  text-align: center;
  justify-content: center !important;
  font-weight: 600 !important;
  font-size: 40px !important;
  letter-spacing: 0px;
  color: rgb(4, 17, 29);
}
.verified {
  margin-top: 10px !important;
  padding: 7px !important;
  color: #fff !important;
  background-color: #1a76d2 !important;
  border-radius: 5px !important;
}
.table {
  margin-top: 25px;
}

hr {
  background-color: #e0e0e0 !important;
  height: 2px;
  border: none;
}
.image {
  width: 40px;
  height: 40px;
  border: 1px solid darkgray;
  border-radius: 20px;
  margin: 10px;
}
.header_content {
  justify-content: space-between;
}
.header_logo {
  display: flex;
}
.logoutBotton {
  color: #fff;
  font-size: 30px !important;
}
.changePassword {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}
.adminProfile {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}
.username {
  color: white;
  margin: 12px 0px 0px 8px !important;
}
@media screen and (max-width: 728px) {
  .header_content {
    display: block;
  }
}
